import { type API } from '@setplex/tria-api'
import { type HttpClient } from '../../http'

import { type AdapterDefaults } from '../../index.h'
import type { ApiNoraTranslations } from '../../interfaces/i18n'
import { translationsFromNoraFormat } from './i18n.format'

export function use(
  http: HttpClient,
  i18n: API['i18n'],
  _api: API,
  _defaults: AdapterDefaults
): void {
  //GET /v3/i18n
  i18n.getAllFx.use(async () => {
    const json = await http.get<ApiNoraTranslations>(`/v3/i18n`)
    if (!json || !json.payload) {
      throw new Error('Empty answer in i18n.getAllFx')
    }

    return translationsFromNoraFormat(json.payload?.content || [])
  })
}
