import { register } from '!/repository'
import { api } from '@setplex/tria-api'
import { createEvent, createStore } from 'effector'
import { produce } from 'immer'
import { model as session } from '~/entities/session'
import type { Favorites } from './index.h'
import { update } from './lib'

export const init = createEvent()

// this store subsribes to ALL POSSIBLE requests,
// which could get and/or update `favorite` flag on any entity
export const $favorites = createStore<Favorites>({
  channel: {},
  vod: {},
  show: {},
})
  // reset to default on any session change
  .reset(session.$session)

  // update channels exclusively
  .on(
    [
      api.tv.getChannelsByCategoryIdFx.doneData, // /v3/channels/tv?categoryId=categoryId
      api.tv.getTvChannelsByIdFx.doneData, // /v3/channels/tv
      api.tv.getTvChannelsByIdUpdatedOnlyFx.doneData, // /v3/channels/tv?updatedTimeOnly=true
      api.tvBundles.getTvBundleContentFx.doneData, // /v3/bundles/channels/{id}/content
      api.tvBundles.getTvBundlesFx.doneData, // /v3/bundles/channels
      api.tv.getAllTvChannelsByPageFx.doneData, // /v3/channels?type=TV
      api.tv.getPurchasedTvChannelsGridFx.doneData,
      api.tv.getPurchasedTvChannelsRowFx.doneData,
      // /v3/channels
      // /v3/channels/radio
      // /v3/library/channels/tv
    ],
    update('channel')
  )

  // update vods exclusively
  .on(
    [
      api.vodBundles.getVodBundleContentFx.doneData, // /v3/bundles/vods/{bundleId}/content
      api.vods.getPageFx.doneData, // /v3/vods
      api.vods.getUpdatedOnlyFx.doneData, // /v3/vods?updatedTimeOnly=true
      api.vods.getVodFx.doneData, // /v3/vods/{vodId}
      api.vods.getVodsByCategoryIdFx.doneData, // /v3/vods?categoryId=categoryId
      api.vods.getPurchasedVodsGridFx.doneData,
      api.vods.getPurchasedVodsRowFx.doneData,
      // /v3/library/vods
    ],
    update('vod')
  )

  // update shows exclusively
  .on(
    [
      api.showBundles.getShowBundleContentFx.doneData, // /v3/bundles/tv-shows/{bundleId}/content
      api.tvshows.getAllTvShowsFx.doneData, // /v3/tvshows
      api.tvshows.getLastAddedTvShowsFx.doneData, // /v3/tvshows?sort-by=updatedTime&sort-order=desc
      api.tvshows.getTvShowBaseFx.doneData, // /v3/tvshows?ids=tvShowId
      api.tvshows.getShowsByCategoryIdFx.doneData, // /v3/tvshows?categoryId=categoryId
      api.tvshows.getTvShowsUpdatedOnlyFx.doneData, // /v3/tvshows?updated-only=true
      api.tvshows.getPurchasedTvShowsGridFx.doneData,
      api.tvshows.getPurchasedTvShowsRowFx.doneData,
      // /v3/library/tvshows
    ],
    update('show')
  )

  // update channels, vods or shows depending on `contentType` field
  .on(
    [
      api.banners.getBannersFx.doneData, // /v3/carousels/banners
      api.banners.getFeaturedCarouselFx.doneData, // /v3/carousels/featured
      // /v3/continue-watching
    ],
    update()
  )

  // update channels, vods or shows depending on object part
  .on(
    [
      api.globalSearch.getSearchFx.doneData, // /v3/search
      // /v3/library/records/{recordId}
      // /v3/records/{recordId}
      // /v3/library/records
      // /v3/records
      // /v3/library/records
      // /v3/records
    ],
    (favorites, payload) => {
      favorites = update('channel')(favorites, payload.radioChannels)
      favorites = update('channel')(favorites, payload.tvChannels)
      favorites = update('show')(favorites, payload.tvShows)
      favorites = update('vod')(favorites, payload.vods)
      return favorites
    }
  )

  // update channel on update request
  .on(
    api.tv.updateTvChannelFx.done, // PATCH >>> /v3/channels/tv/{channelId}
    update('channel') // this will traverse `params` and will find { id, favorite } there
  )
  .on(
    api.tv.updateTvChannelFx.fail, // PATCH >>> /v3/channels/tv/{channelId}
    (favorites, { params }) =>
      produce(favorites, (draft) => {
        draft.channel[params.id] = !params.favorite // in case of error - restore previous value
      })
  )

  // update vod on update request
  .on(
    api.vods.updateVodFx.done, // PATCH >>> /v3/vods/{vodId}
    update('vod') // this will traverse `params` and will find { id, favorite } there
  )
  .on(
    api.vods.updateVodFx.fail, // PATCH >>> /v3/vods/{vodId}
    (favorites, { params }) =>
      produce(favorites, (draft) => {
        if (typeof params.favorite === 'boolean') {
          draft.vod[params.id] = !params.favorite // in case of error - restore previous value
        }
      })
  )

  // update show on update request
  .on(
    api.tvshows.updateTvShowFx.done, // PATCH >>> /v3/tvshows/{tvShowId}
    update('show') // this will traverse `params` and will find { id, favorite } there
  )
  .on(
    api.tvshows.updateTvShowFx.fail, // PATCH >>> /v3/tvshows/{tvShowId}
    (favorites, { params }) =>
      produce(favorites, (draft) => {
        draft.show[params.id] = !params.favorite // in case of error - restore previous value
      })
  )

//
// register stores and events
//

register($favorites, '$feature/favorites')
