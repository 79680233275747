import type {
  API,
  CategoryWithChannelsApi,
  EpgInfo,
  EpgInfoDto,
  GenericApiResponse,
  MediaUrl,
  Page,
  TvCategory,
  TvChannel,
  TvEpgApiDto,
} from '@setplex/tria-api'
import { CarouselTypes as CarouselTypesEnum } from '@setplex/tria-api'
import { type ChannelDto } from '@setplex/wbs-api-types'
import { DEFAULT_PAGE_SIZE } from '../../constants/generic'
import { type HttpClient } from '../../http'
import type { AdapterDefaults } from '../../index.h'
import type {
  ApiAnswerTvCategoriesByPage,
  ApiAnswerTvChannelUrl,
  ApiAnswerTvChannelsByPage,
  ApiAnswerTvChannelsList,
  ApiAnswerTvChannelsListUpdatedOnly,
} from '../../interfaces/tv'
import { getFirstPagePaginateInfo } from '../../utils/pageInfo'
import {
  formatChannel,
  formatChannelPlaylist,
  formatChannelUpdatedOnly,
  formatEPGArray,
  formatEPGPrograms,
  getPaginatedTvPlaylist,
} from './tv.format'

const arrayToQueryParam = (param: string, array: number[] | string[]) =>
  array
    ?.map((id) => `&${param}=${String(id)}`)
    .join('')
    .slice(1)

const epgToDateStringFormat = (str: number) =>
  new Date(str).toISOString().replace(/\.\d{3}Z$/, 'Z')

export function use(
  http: HttpClient,
  tv: API['tv'],
  _api: API,
  defaults: AdapterDefaults
): void {
  // GET /v3/channels
  tv.getAllTvChannelsByPageFx.use(
    async ({
      page = 0,
      count = defaults.count || 36,
      categoryId,
      'only-favorites': onlyFavorites,
    }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))

      if (categoryId != null) params.set('categoryId', String(categoryId))
      if (onlyFavorites != null)
        params.set('only-favorites', String(onlyFavorites))

      let json
      try {
        json = await http.get<ApiAnswerTvChannelsByPage>(
          `/v3/channels?${params}&type=TV`
        )
      } catch (apiCallError) {
        console.log('getAllTvChannelsByPageFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getAllTvChannelsByPageFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatChannel),
      }
    }
  )

  // GET /v3/library/channels/tv
  tv.getPurchasedTvChannelsFx.use(
    async ({
      page = 0,
      count = defaults.count || 36,
      rented = true,
      purchased = true,
    }) => {
      let json
      try {
        json = await http.get<ApiAnswerTvChannelsByPage>(
          `/v3/library/channels/tv`,
          {
            searchParams: {
              page,
              count,
              rented,
              purchased,
            },
          }
        )
      } catch (apiCallError) {
        console.log('getPurchasedTvChannelsFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getPurchasedTvChannelsFx')
      }

      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatChannel),
      }
    }
  )

  // GET /v3/channels/tv
  tv.getTvChannelsByIdFx.use(async ({ ids }) => {
    const params = new URLSearchParams()
    const idsParams = arrayToQueryParam('ids', ids)

    let json
    try {
      json = await http.get<ApiAnswerTvChannelsList>(
        `/v3/channels/tv?${params}&${idsParams}`
      )
    } catch (apiCallError) {
      console.log('getTvChannelsByIdFx error ', apiCallError)
    }
    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvChannelsByIdFx')
    }
    return json.payload.map(formatChannel) as TvChannel[]
  })

  // GET /v3/channels/tv?updatedTimeOnly=true
  tv.getTvChannelsByIdUpdatedOnlyFx.use(async ({ categoryId }) => {
    const params = new URLSearchParams()
    if (categoryId != null) params.set('categoryId', String(categoryId))

    let json
    try {
      json = await http.get<ApiAnswerTvChannelsListUpdatedOnly>(
        `/v3/channels/tv?${params}&updatedTimeOnly=true`
      )
    } catch (apiCallError) {
      console.log('getTvChannelsByIdUpdatedOnlyFx error ', apiCallError)
    }
    if (!json || !json.payload) {
      throw new Error('Empty answer in getTvChannelsByIdUpdatedOnlyFx')
    }
    return json.payload.map(formatChannelUpdatedOnly)
  })

  // GET /v3/channels/tv/{channelId}/url
  tv.getTvChannelUrlFx.use(
    async ({ channelId, startTime, endTime, isRewind }) => {
      const params = new URLSearchParams()
      if (startTime != null && isRewind)
        params.set('startTime', String(startTime))
      if (endTime != null && isRewind) params.set('endTime', String(endTime))
      if (isRewind) params.set('live-stream-supported', String(isRewind))

      const json = await http.get<ApiAnswerTvChannelUrl>(
        `/v3/channels/tv/${channelId}/url${params.size ? `?${params}` : ''}`
      )

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvChannelUrlFx')
      }

      return {
        ...json.payload,
        timeShift: startTime,
        rewindTimeEnded: startTime ? Math.round(Date.now() / 1000) : undefined,
      } as MediaUrl
    }
  )

  // GET /v3/channels/tv/categories
  tv.getTvCategoriesByPageFx.use(
    async ({ page, count = defaults.count || 36 }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))

      let json
      try {
        json = await http.get<ApiAnswerTvCategoriesByPage>(
          `/v3/channels/tv/categories?${params}`
        )
      } catch (apiCallError) {
        console.log('getTvCategoriesByPageFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getTvCategoriesByPageFx')
      }

      return {
        ...json.payload,
        content: json.payload.content || [],
      } as Page<TvCategory>
    }
  )

  // GET /v3/channels/tv?categoryId=categoryId
  tv.getChannelsByCategoryIdFx.use(
    async ({ page, count = defaults.count || 36, categoryId }) => {
      const params = new URLSearchParams()
      params.set('page', String(page))
      params.set('count', String(count))
      if (categoryId) params.set('categoryId', String(categoryId))

      let json
      try {
        json = await http.get<ApiAnswerTvCategoriesByPage>(
          `/v3/channels?type=TV&${params}`
        )
      } catch (apiCallError) {
        console.log('getChannelsByCategoryIdFx error ', apiCallError)
      }

      if (!json || !json.payload) {
        throw new Error('Empty answer in getChannelsByCategoryIdFx')
      }
      return {
        ...json.payload,
        content: (json.payload.content || []).map(formatChannel),
      } as Page<TvChannel>
    }
  )

  // PATCH /v3/channels/tv/{channelId}
  tv.updateTvChannelFx.use(async ({ id, favorite }) => {
    const body: { id?: number; favorite?: boolean } = {}
    if (id != null) body.id = Number(id)
    if (favorite != null) body.favorite = Boolean(favorite)

    try {
      return await http.patch(`/v3/channels/tv/${id}`, {
        json: body,
      })
    } catch (apiCallError) {
      console.log('updateTvChannel error ', apiCallError)
    }
  })

  tv.generateTvPlaylist.use(async (params) => {
    const {
      categoryId,
      featured,
      favorite,
      purchased,
      recentlyWatched,
      recommended,
    } = params || {}

    if (recentlyWatched) {
      return {
        categoryId: null,
        channels: [],
        pageInfo: null,
      }
    }

    if (recommended) {
      const channels = await _api.tv.getRealRecommendedTvChannelsFx({})

      return {
        categoryId: null,
        channels: channels.map(formatChannelPlaylist),
        pageInfo: null,
      }
    }

    if (purchased) {
      return getPaginatedTvPlaylist({
        getTvChannelsFX: _api.tv.getPurchasedTvChannelsGridFx,
      })
    }

    if (favorite) {
      return getPaginatedTvPlaylist({
        getTvChannelsFX: _api.tv.getOnlyFavoritesTvChannelsGridFx,
      })
    }

    if (featured) {
      const featuredChannels = await _api.tv.getFeaturedChannels()

      return {
        categoryId: 0,
        channels: featuredChannels.map(formatChannelPlaylist),
        pageInfo: getFirstPagePaginateInfo({
          totalElements: featuredChannels.length,
        }),
      }
    }

    return getPaginatedTvPlaylist({
      getTvChannelsFX: _api.tv.getAllTvChannelsByPageFx,
      categoryId,
    })
  })

  tv.loadMoreTvPlaylistFx.use(async ({ categoryId, page }) => {
    const channels = await tv.getAllTvChannelsByPageFx({
      page: Number(page),
      categoryId,
    })
    return {
      ...channels,
      content: channels.content.map(formatChannelPlaylist),
    }
  })

  const getDefaultAllCategory = (): TvCategory => ({
    id: 0,
    name: 'All Live Channels',
    sortOrder: 0,
    parentCategoryId: 0,
    channelIds: [],
  })
  tv.getTvCategoryFx.use(async ({ categoryId }) => {
    if (categoryId === 0) return getDefaultAllCategory()

    let foundCategory: TvCategory | undefined
    const firstPageCategories = await tv.getTvCategoriesByPageFx({ page: 0 })
    foundCategory = firstPageCategories.content.find(
      (item) => item.id === categoryId
    )

    if (foundCategory) {
      return foundCategory
    }

    let pageIndex: number = 0
    while (pageIndex <= (firstPageCategories.totalPages || 0) - 1) {
      pageIndex++
      const pageCategories = await tv.getTvCategoriesByPageFx({
        page: pageIndex,
      })
      foundCategory = pageCategories.content.find(
        (item) => item.id === categoryId
      )

      if (foundCategory) {
        return foundCategory
      }
    }

    return foundCategory || null
  })

  tv.initChannelsCategoryFx.use(async ({ categoryId }) => {
    const category = await tv.loadTvCategoryFx({
      categoryId,
    })
    const channels = await tv.loadChannelsFx({ categoryId, page: 0 })

    return {
      ...category,
      content: channels.content,
    }
  })

  // GET /v3/channels/tv?only-recommended=true
  tv.getRealRecommendedTvChannelsFx.use(
    async ({ count = defaults.count || DEFAULT_PAGE_SIZE }) => {
      let json
      try {
        json = await http.get<GenericApiResponse<ChannelDto[]>>(
          `/v3/channels/tv?only-recommended=true&count=${count}`
        )
      } catch (apiCallError) {
        console.log(' error ', apiCallError)
      }
      if (!json || !json.payload) {
        throw new Error('Empty answer getRealRecommendedTvChannelsFx')
      }

      return (json.payload || []).map(formatChannel)
    }
  )

  tv.getCategoriesWithChannelsFx.use(async ({ page, count }) => {
    const pageOfCategories = await tv.getTvCategoriesByPageFx({ page, count })
    const categoriesIdsArray = pageOfCategories.content.map(
      (category) => category.id
    )

    const channelsPromises = categoriesIdsArray.map((categoryId) =>
      tv
        .getChannelsByCategoryIdFx({
          page: 0,
          categoryId: Number(categoryId),
        })
        .then((data) => ({
          categoryId: Number(categoryId),
          data,
        }))
        .catch(() => ({
          categoryId: Number(categoryId),
          data: undefined,
        }))
    )

    const channelsPages = await Promise.all(channelsPromises)

    const formattedCategory = pageOfCategories.content.map(
      (category): CategoryWithChannelsApi => {
        const channelsPageOfCategory = channelsPages.find(
          (channels) => channels.categoryId === category.id
        )?.data

        const isMoreThanOnePage =
          !channelsPageOfCategory?.last ||
          channelsPageOfCategory?.content.length > DEFAULT_PAGE_SIZE

        const isChannelExist = channelsPageOfCategory?.content.length
        const pageOfChannels: Page<TvChannel> = isChannelExist
          ? {
              ...channelsPageOfCategory,
              content: channelsPageOfCategory.content,
            }
          : {
              content: [],
              ...getFirstPagePaginateInfo({ totalElements: 0 }),
            }

        return {
          ...category,
          content: pageOfChannels,
          isMoreThanOnePage,
        }
      }
    )

    return {
      ...pageOfCategories,
      content: formattedCategory,
    }
  })

  // POST /media/tv/epg
  tv.getEpgInformationFormattedFx.use(async ({ epgIds, channels, period }) => {
    const body: {
      channelEpgIds: string[]
      fromDate: string
      toDate: string
    } = {
      channelEpgIds: epgIds,
      // format to string BE could handle
      fromDate: new Date(period.start).toISOString().replace(/\.\d{3}Z$/, 'Z'),
      toDate: epgToDateStringFormat(period.end),
    }

    if (!epgIds?.length) return { epg: {}, period } // no sense request // b.e. returns empty obj in that case

    let json
    try {
      json = await http.post<TvEpgApiDto>(`/media/tv/epg`, {
        json: body,
      })
    } catch (apiCallError) {
      console.log('getEpgInformationFormattedFx error ', apiCallError)
    }
    if (!json) {
      throw new Error('Empty answer in getEpgInformationFormattedFx')
    }

    return {
      epg: formatEPGPrograms(json, channels, period),
      period,
    }
  })

  // POST /media/tv/epg
  tv.getEpgInformationFx.use(async ({ epgIds, period }) => {
    const body: { channelEpgIds: string[]; fromDate: string; toDate: string } =
      {
        channelEpgIds: epgIds,
        fromDate: new Date(period.start)
          .toISOString()
          .replace(/\.\d{3}Z$/, 'Z'),
        toDate: epgToDateStringFormat(period.end),
      }

    if (!epgIds?.length) return { epg: {}, period } // no sense request // b.e. returns empty obj in that case

    let json
    try {
      json = await http.post<TvEpgApiDto>(`/media/tv/epg`, {
        json: body,
      })
    } catch (apiCallError) {
      console.log('getEpgInformationFx error ', apiCallError)
    }
    if (!json) {
      throw new Error('Empty answer in getEpgInformationFx')
    }
    return {
      epg: json,
      period,
    }
  })

  // GET /media/tv/epg
  tv.getProgramEPGInfoFx.use(async ({ epgId, programTitle, start }) => {
    // request accepts only start coz Get /media/tv/epg/ accepts only start and its period - 7 days
    if (!epgId) return [] as EpgInfo[] // no sense request // b.e. returns empty array in that case

    const startFormatted = new Date(start)
      .toISOString()
      .replace(/\.\d{3}Z$/, 'Z')

    const query = `${epgId}?title=${programTitle}&start=${startFormatted}`

    let json
    try {
      json = await http.get<EpgInfoDto[]>(`/media/tv/epg/${query}`)
    } catch (apiCallError) {
      console.log('getProgramEPGInfoFx error ', apiCallError)
    }
    if (!json) {
      throw new Error('Empty answer in getProgramEPGInfoFx')
    }

    return formatEPGArray(epgId, json)
  })

  tv.getCategoriesWithChannelsFx.use(async ({ page, count }) => {
    const pageOfCategories = await tv.getTvCategoriesByPageFx({ page, count })
    const categoriesIdsArray = pageOfCategories.content.map(
      (category) => category.id
    )

    const channelsPromises = categoriesIdsArray.map((categoryId) =>
      tv
        .getChannelsByCategoryIdFx({
          page: 0,
          categoryId: Number(categoryId),
        })
        .then((data) => ({
          categoryId: Number(categoryId),
          data,
        }))
        .catch(() => ({
          categoryId: Number(categoryId),
          data: undefined,
        }))
    )

    const channelsPages = await Promise.all(channelsPromises)

    const formattedCategory = pageOfCategories.content.map(
      (category): CategoryWithChannelsApi => {
        const channelsPageOfCategory = channelsPages.find(
          (channels) => channels.categoryId === category.id
        )?.data

        const isMoreThanOnePage =
          !channelsPageOfCategory?.last ||
          channelsPageOfCategory?.content.length > DEFAULT_PAGE_SIZE

        const isChannelExist = channelsPageOfCategory?.content.length
        const pageOfChannels: Page<TvChannel> = isChannelExist
          ? {
              ...channelsPageOfCategory,
              content: channelsPageOfCategory.content,
            }
          : {
              content: [],
              ...getFirstPagePaginateInfo({
                totalElements: 0,
              }),
            }

        return {
          ...category,
          content: pageOfChannels,
          isMoreThanOnePage,
        }
      }
    )

    return {
      ...pageOfCategories,
      content: formattedCategory,
    }
  })

  tv.getFeaturedChannels.use(async () => {
    const featured = await _api.banners.getFeaturedCarouselFx()

    const featuredTvCarousel = featured.filter(
      (carousel) => carousel.type === CarouselTypesEnum.TV_CHANNEL
    )[0].content

    return featuredTvCarousel.map(({ channel }) => channel as TvChannel)
  })
}
