import { t } from '!/i18n'
import { ONE_HOUR_MS, ONE_MINUTE_MS, ONE_SECOND_MS } from '@/constants/time'
import { nay } from '@/helpers'
import {
  formatDateDuration,
  formatYearToLocaleString,
  getFormattedDate,
} from '@/helpers/date'
import {
  PriceSettingsPaymentMethod,
  PurchaseInfoContentPurchaseType as PurchaseInfoContentPurchaseTypeEnum,
  type FormattedPriceSettings,
  type PurchaseInfo,
  type TvChannel,
  type TvShowEpisode,
  type TvShowSeason,
} from '@setplex/tria-api'
import type { TFunction } from 'i18next'
import type {
  ContentItem,
  ContentItemWithPrice,
  TvShowEpisodeWithPrice,
  TvShowSeasonWithPrice,
  TvWithPrice,
} from '~/shared/interfaces'

const getLabelPrice = ({
  free,
  purchaseInfo,
  minPrice,
  minCurrency,
}: Pick<
  ContentItem,
  'free' | 'purchaseInfo' | 'minPrice' | 'minCurrency'
>): string => {
  if (free || (!purchaseInfo && !minCurrency && !minPrice)) return ''
  if (purchaseInfo) {
    const label = getLabelPriceForPurchasedItem({
      purchaseInfo: purchaseInfo as PurchaseInfo,
    })
    return label ? label : ''
  }
  return t('price', { minCurrency, minPrice })
}

export const isPriceLabelShown = (priceSettings?: FormattedPriceSettings[]) => {
  if (!priceSettings) return false

  const hasPerItem = priceSettings.some(
    ({ paymentMethod }) => paymentMethod === PriceSettingsPaymentMethod.PerItem
  )
  const hasPerSet = priceSettings.some(
    ({ paymentMethod }) => paymentMethod === PriceSettingsPaymentMethod.PerSet
  )

  //We should show the price label only if the item has paymentMethod === PER_SET and no paymentMethod === PER_ITEM
  return !(hasPerSet && !hasPerItem)
}

export const extendMediaItemByLabelPrice = (
  item: ContentItem,
  isBundle = false
): ContentItemWithPrice => {
  const { purchaseInfo, minCurrency, minPrice, free, priceSettings } = item

  let labelPrice = ''

  if ((isBundle && isPriceLabelShown(priceSettings)) || nay(isBundle))
    labelPrice = getLabelPrice({ free, purchaseInfo, minPrice, minCurrency })

  return {
    ...item,
    labelPrice,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    titleButton: minPrice
      ? t('watch_for', {
          price: minPrice,
          currency: minCurrency,
        })
      : '',
  }
}

export const extendMediaItemByLabelPriceTvChannel = (
  item: TvChannel,
  isBundle = false
): TvWithPrice => {
  const {
    purchaseInfo,
    minCurrency,
    minPrice,
    free,
    name,
    channelNumber,
    priceSettings,
  } = item

  let labelPrice = ''

  if ((isBundle && isPriceLabelShown(priceSettings)) || nay(isBundle))
    labelPrice = getLabelPrice({ free, purchaseInfo, minPrice, minCurrency })

  return {
    ...item,
    labelPrice,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    titleButton: minPrice
      ? t('watch_for', {
          price: minPrice,
          currency: minCurrency,
        })
      : '',
    formattedName: `${channelNumber}. ${name}`,
  }
}

export const extendMediaItemByLabelPriceTvShowSeason = (
  item: TvShowSeason
): TvShowSeasonWithPrice => {
  const { purchaseInfo, minCurrency, minPrice, free, year } = item
  const labelPrice = getLabelPrice({
    free,
    purchaseInfo,
    minPrice,
    minCurrency,
  })

  return {
    ...item,
    labelPrice,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    titleButton: minPrice
      ? t('watch_for', { currency: minCurrency, price: minPrice })
      : '',
    ...(year && { year: formatYearToLocaleString(Number(year)) }),
  }
}

export const extendMediaItemByLabelPriceTvShowEpisode = (
  item: TvShowEpisode
): TvShowEpisodeWithPrice => {
  const { purchaseInfo, minCurrency, minPrice, free } = item

  const labelPrice = getLabelPrice({
    free,
    purchaseInfo,
    minPrice,
    minCurrency,
  })

  return {
    ...item,
    rented:
      purchaseInfo?.contentPurchaseType ===
      PurchaseInfoContentPurchaseTypeEnum.Rented,
    labelPrice: labelPrice,
  }
}

export const getLabelPriceForPurchasedItem = ({
  purchaseInfo,
}: {
  purchaseInfo: PurchaseInfo
}): string | undefined => {
  if (!purchaseInfo) return

  switch (purchaseInfo.contentPurchaseType) {
    case PurchaseInfoContentPurchaseTypeEnum.Rented: {
      const rentedUntilInMillis = purchaseInfo.rentedUntil
        ? new Date(purchaseInfo.rentedUntil).getTime()
        : Date.now()
      return getFormattedDate(rentedUntilInMillis)
    }
    case PurchaseInfoContentPurchaseTypeEnum.Bought:
    default:
      return
  }
}

/**
 * Return the duration of content viewed as a percentage
 * @param stoppedTime - stop time while browsing
 * @param duration - content duration
 * @example duration = 10, stoppedTime = 2 -> 20%
 */
export const getProgress = ({
  duration,
  stoppedTime,
}: {
  duration?: number | null
  stoppedTime?: number | null
}): number | null => {
  if (!duration || !stoppedTime) return null

  return (stoppedTime * 100) / duration
}

const getTime = (timeLeftMs: number) => {
  const hours = Math.floor(timeLeftMs / ONE_HOUR_MS)
  const minutes = Math.floor((timeLeftMs - hours * ONE_HOUR_MS) / ONE_MINUTE_MS)
  const seconds = Math.floor(
    (timeLeftMs - minutes * ONE_MINUTE_MS) / ONE_SECOND_MS
  )

  return { hours, minutes, seconds }
}

export const getLeftTimeString = ({
  duration,
  stoppedTime,
  t,
}: {
  duration?: number | null
  stoppedTime?: number
  t: TFunction
}): string => {
  if (!duration || !stoppedTime) return ''

  const timeLeftMs = duration - stoppedTime
  const { hours, minutes } = getTime(timeLeftMs)
  const time = formatDateDuration({
    duration: { hours, minutes },
    short: true,
  })

  return t('time_left', {
    time,
  })
}

export const getViewedTimeString = (stoppedTime?: number): string => {
  if (!stoppedTime) return ''

  const { hours, minutes } = getTime(stoppedTime)
  const time = formatDateDuration({
    duration: { hours, minutes },
    short: true,
  })

  return time
}
