import {
  type AccessTokenDto,
  type SubscriberInfoDto,
} from '@setplex/wbs-api-types'
import {
  createEffect,
  createEvent,
  createStore,
  sample,
  type Store,
} from 'effector'
import { http } from '../events'
import type { ApiEffect } from '../index.h'
import { set as setSession, type Session } from './session'

export enum SubscriberGender {
  Male,
  Female,
  Other,
  Unknown,
  NopApplicable,
}

export enum SubscriberRegistrationStatus {
  IN_PROCESS = 'IN_PROCESS',
  DONE = 'DONE',
  FAILED = 'FAILED',
  WAITING_EMAIL_CONFIRMATION = 'WAITING_EMAIL_CONFIRMATION',
  EXPIRED = 'EXPIRED',
}

export interface Subscriber extends Omit<SubscriberInfoDto, 'gender'> {
  name: string
  gender: SubscriberGender
  phone?: string
  birthday?: Date
}

export interface ResetPasswordSession {
  id: string
  time_period: string
  time_value: number
}

interface InitResetPasswordData {
  email: string
}

export interface Features {
  facebookLoginEnabled?: boolean
  partnersProgramEnabled?: boolean
  guestModeEnabled?: boolean
  registrationEnabled?: boolean
  resetPasswordEnabled?: boolean
  signInWithPhoneEnabled?: boolean
  countryLocation?: {
    country: string
  }
}

export interface AccountAccessToken extends Required<AccessTokenDto> {}
//*
//* EFFECTS
//*

export const getFeaturesFx: ApiEffect<void, Features> = createEffect()

export const signUpFx: ApiEffect<
  { email: string; password: string; username: string },
  {
    id: string
    time_value: number
    time_period: string
  }
> = createEffect()

export const signUpByPhoneFx: ApiEffect<
  { phoneNumber: string },
  {
    id: string
    time_value: number
    time_period: string
  }
> = createEffect()

export const getRegistrationStatusFx: ApiEffect<
  { id: string },
  { status: SubscriberRegistrationStatus }
> = createEffect()

export const resendConfirmationEmailFx: ApiEffect<{ id: string }, void> =
  createEffect()

export const resendConfirmationPhoneFx: ApiEffect<{ id: string }, void> =
  createEffect()

export const confirmCodeRegistrationFx: ApiEffect<
  { id: string; code: string },
  { firstName: string; lastName: string; email: string }
> = createEffect()

export const confirmCodeRegistrationByPhoneFx: ApiEffect<
  { id: string; code: string; rememberMe: boolean },
  { code: number; payload: Session }
> = createEffect()

export const initResetPasswordFx: ApiEffect<
  InitResetPasswordData,
  ResetPasswordSession
> = createEffect()

export const verifyResetPasswordCodeFx: ApiEffect<
  { id: string; code: string },
  void
> = createEffect()

export const resendResetPasswordCodeFx: ApiEffect<{ id: string }, void> =
  createEffect()

export const setNewPasswordFx: ApiEffect<
  { id: string; code: string; password: string },
  void
> = createEffect()

export const getAccountAccessTokenFx: ApiEffect<void, AccountAccessToken> =
  createEffect()
export const getFx: ApiEffect<void, Subscriber> = createEffect()
export const set = createEvent<Subscriber>()
export const reset = createEvent<void>()

//*
//* CONNECTIONS
//*

sample({
  clock: http.unauthorized,
  target: reset,
})

//*
//* STORES
//*

export const $subscriber: Store<Subscriber | null> =
  createStore<Subscriber | null>(null)
    .on(set, (_, subscriber) => subscriber)
    .on(getFx.doneData, (_, subscriber) => subscriber)
    .reset(reset)

sample({
  clock: confirmCodeRegistrationByPhoneFx.doneData,
  filter: ({ code, payload }) => code === 200 && payload != null,
  fn: ({ payload }) => payload,
  target: setSession,
})
