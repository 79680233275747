import { Logger, logger } from './logger'

export { Logger, logger }

declare global {
  // disable `no-var` rule because this is not a variable declarations,
  // but a type definitions, and it works only with `var`

  /* eslint-disable no-var */
  var __console: Console
  /* eslint-enable no-var */
}

// save original console
globalThis.__console = globalThis.console

// mock console with our logger
globalThis.console = new Proxy(new Logger('console'), {
  get(target, prop, receiver) {
    const fn = Reflect.get(target, prop, receiver)
    return fn instanceof Function
      ? fn.bind(target)
      : Reflect.get(globalThis.__console, prop, receiver)
  },
}) as unknown as Console
