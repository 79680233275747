import { env } from '@/config'
import {
  isAbortError,
  isTimeoutError,
  merr,
  type MerrInit,
} from '@setplex/merr'
import {
  RequestCancelledError,
  RequestFailedError,
  RequestHttpError,
  RequestTimeoutError,
  type RequestError,
  type RequestInput,
  type RequestOutput,
} from '@setplex/tria-api'
import { v4 as uuidv4 } from 'uuid'
import { runtime, versions } from '~/app'
import { session } from './model'
import { retry } from './retry_policy'

const DEFAULT_HTTP_TIMEOUT = 10_000

export async function request(input: RequestInput): Promise<RequestOutput> {
  const init: MerrInit = {
    // http request timeout
    timeout: Number.isFinite(env.TRIA_CLIENT_HTTP_TIMEOUT)
      ? env.TRIA_CLIENT_HTTP_TIMEOUT
      : DEFAULT_HTTP_TIMEOUT,

    // retry policy
    retry,

    // static and dynamic headers
    headers: {
      'accept': 'application/json',

      // wbs/nora specific headers
      'mw-platform': 'WEB',
      'mw-app-name': runtime.app.name, // got from era
      'mw-device-type': runtime.device.type.toUpperCase(), // DESKTOP / TABLET / MOBILE
      'mw-device-os': runtime.os.name, // Linux / Android / iOS / MacOS / WebOS / Tizen / FireOS / Windows
      'mw-device-os-version': runtime.os.version,
      'mw-device-app-version': versions.tria,
      'mw-supported-drm-providers': 'EZDRM', // EXPRESS_PLAY / EZDRM / IRDETO / PALLYCON
      'mw-supported-drm-types': runtime.drms.join(','), // WIDEVINE / PLAYREADY / FAIRPLAY / MARLIN
      'mw-pid': session.pid, // provider id
      'mw-package-id': session.packageid, // package id
      'mw-web-metadata': JSON.stringify({
        browser: runtime.browser.name,
        browserVersion: runtime.browser.version,
      }),
      'mw-idempotency-key': uuidv4(), // https://setplexapps.atlassian.net/wiki/spaces/MW/pages/2682454084/Client+App+Request+Policy
    },
  }

  // RequestOutput object fields to return
  let response: Response | undefined
  let error: RequestError | undefined
  let json: any // eslint-disable-line @typescript-eslint/no-explicit-any

  try {
    response = await merr(input, init)

    // extract json from response, if possible
    try {
      json = await response.clone().json()
    } catch (e) {
      // ignore
    }

    // if response is not 2xx - add http error
    if (!response.ok) {
      error = new RequestHttpError(response)
    }
  } catch (e) {
    // on error, return timeout/cancelled/failed error
    // prettier-ignore
    /* @formatter:off */
    // eslint-disable-next-line no-nested-ternary
    error = isTimeoutError(e) ? new RequestTimeoutError(e)
            : isAbortError(e) ? new RequestCancelledError(e)
              /* TypeError */ : new RequestFailedError(e as Error)
  }

  return { response, json, error }
}
