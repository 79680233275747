import { model as config, remote } from '@/config'
import { createEvent, createStore, sample } from 'effector'
import { version } from '~/app/environment'
import { model as session } from '~/entities/session'
import { aye, nay } from '~/shared/helpers'
import {
  NPAW_DEVICE_CODE,
  NPAW_PLUGIN_ADAPTER_URL,
  type PlayerNpawAppData,
} from './index.h'

export const init = createEvent()

export const $playerNpaw = createStore<PlayerNpawAppData | null>(null)

export const $playerNpawPluginConfig = config.get(
  remote.tria_playerNpawPluginConfig
)

sample({
  source: {
    session: session.$session,
    playerNpawPluginConfig: $playerNpawPluginConfig,
  },
  filter: ({ session }) => aye(session),
  fn: ({ playerNpawPluginConfig, session }) => {
    const { balancerOptions, accountCode, enabled } = JSON.parse(
      playerNpawPluginConfig
    )

    const npawAccount: PlayerNpawAppData = {
      contentCustomDimensionDeviceId: session?.deviceIdentifier,
      userName: session?.subscriberName,
      deviceId: session?.deviceIdentifier,
      deviceCode: NPAW_DEVICE_CODE,
      appReleaseVersion: version,
      balancerProfileName: balancerOptions.profileName,
      balancerBucketName: balancerOptions.bucketName,
      accountCode: accountCode,
      adapterUrl: NPAW_PLUGIN_ADAPTER_URL,
      contentPackage: session?.packageName,
      enabled,
    }

    return npawAccount
  },
  target: $playerNpaw,
})

sample({
  clock: session.$session,
  filter: (session) => nay(session),
  target: $playerNpaw.reinit,
})
