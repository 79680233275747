import { model as config, remote } from '@/config'
import { or } from 'patronum'

export const $commonSearchEnabled = config.get(
  remote.tria_isCommonSearchEnabled
)

export const $globalSearchDefaultEnabled = config.get(
  remote.tria_isGlobalSearchEnabled
)

export const $globalSearchByContentTypeEnabled = config.get(
  remote.tria_isGlobalSearchByContentTypeEnabled
)

export const $globalSearchEnabled = or(
  $commonSearchEnabled,
  $globalSearchDefaultEnabled,
  $globalSearchByContentTypeEnabled
)
