export const NPAW_DEVICE_CODE = 'web'
export const NPAW_PLUGIN_ADAPTER_URL =
  '/player/npaw-plugin-videojs-adapter.json'

export const NPAW_TRAILER_NAME = '(Trailer)'

export enum PlayerNpawContent {
  VOD = 'vod',
  TVSHOW = 'tvshow',
  CATCHUP = 'catchup',
  TV = 'tv',
}

export type PlayerNpawAppData = {
  contentCustomDimensionDeviceId?: string
  userName?: string
  deviceId?: string
  deviceCode: 'web'
  appReleaseVersion: string
  balancerProfileName?: string
  balancerBucketName?: string
  accountCode: string
  adapterUrl: string
  enabled: boolean
  contentPackage?: string | null
}

export type PlayerNpawContentData = {
  contentTitle?: string
  contentProgram?: string
  contentIsLive: boolean
  contentId: string
  contentPlaybackType: PlayerNpawContent | null
}

export type PlayerNpawData = PlayerNpawAppData & PlayerNpawContentData
